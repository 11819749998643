import { useAuth0 } from "@auth0/auth0-react";
import { Image, Popover, Switch, Tooltip } from "antd";
import { GlobalContext } from "../../../context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { FiHelpCircle, FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineSetting } from "react-icons/ai";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { useAccess } from "../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../helpers/access";
import { updateUserStatus } from "../../../../helpers/fetchData/fetchInstance";
import { getAvailableAgents } from "../../../../helpers/fetchData/fetchChats";
import { useIsBrocolyAdminInstance } from "../../../../hooks/useIsBrocolyAdminInstance";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

const BottomMenu = () => {
  const { logout, user } = useAuth0();

  const {
    sideDrawerConfig,
    setSideDrawerConfig,
    setInstance,
    instance,
    messageApi,
    isUserActive,
    setIsUserActive,
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const isAllowed = useAccess();

  const { isBrocolyAdminInstance } = useIsBrocolyAdminInstance();

  const [loadingStatus, setLoadingStatus] = useState(false);

  const handleUserStatus = (v) => {
    if (instance?.userData?.userId) {
      setLoadingStatus(true);
      const data = {
        agent: instance.userData.userId,
        status: v,
      };

      updateUserStatus(data, instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (v) {
              if (
                res.data?.message
                  ?.toLowerCase()
                  ?.startsWith("ya existe un estado")
              ) {
                setIsUserActive(true);
              } else {
                setIsUserActive(data.status);
              }
            } else {
              setIsUserActive(data.status);
            }
          } else {
            messageApi.error("Ocurrió un error al cambiar su disponibilidad");
          }
        })
        .catch((error) => {
          messageApi.error("Ocurrió un error al cambiar su disponibilidad");
          console.log(error);
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getAvailableAgents(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            const array = res.data || [];
            // setIsUserActive(array.includes(instance.userData.userId));
            setIsUserActive(array.some(item => item.agentId === instance.userData.userId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [instance]);

  const handleChange = (route) => {
    navigate(route);
    setSideDrawerConfig({ visible: false, content: "" });
  };

  return (
    <div className="sidebar-bottom">
      {isBrocolyAdminInstance && (
        <Tooltip
          placement="right"
          title={"Administración brocoly"}
          mouseEnterDelay={0.3}
        >
          <div
            className={
              window.location.pathname.includes("admin") &&
              !sideDrawerConfig.visible
                ? "item-menu-selected"
                : "item-menu"
            }
            onClick={() => handleChange("/brocoly-admin")}
            style={{ marginBottom: "16px" }}
          >
            <MdOutlineAdminPanelSettings
              className={
                window.location.pathname.includes("admin") &&
                !sideDrawerConfig.visible
                  ? "icon-menu-selected"
                  : "icon-menu"
              }
            />
          </div>
        </Tooltip>
      )}
      {isAllowed(accessDataFormat("settings", "show")) && (
        <Tooltip
          placement="right"
          title={"Configuración"}
          mouseEnterDelay={0.3}
        >
          <div
            className={
              window.location.pathname.includes("settings") &&
              !sideDrawerConfig.visible
                ? "item-menu-selected"
                : "item-menu"
            }
            onClick={() => handleChange("/settings")}
            style={{ marginBottom: "16px" }}
          >
            <AiOutlineSetting
              className={
                window.location.pathname.includes("settings") &&
                !sideDrawerConfig.visible
                  ? "icon-menu-selected"
                  : "icon-menu"
              }
            />
          </div>
        </Tooltip>
      )}
      <div className="user-menu" style={{ borderRadius: "50px" }}>
        <Popover
          trigger="click"
          placement="rightBottom"
          arrow={false}
          content={
            <div className="columna">
              <span
                className="horizontal"
                style={{
                  borderBottom: "1px solid #d8d8d8",
                  paddingBottom: "12px",
                  alignItems: "flex-start",
                }}
              >
                {user && (
                  <>
                    <span className="columna">
                      <b style={{ fontSize: "16px" }}>{user.name}</b>
                      <p style={{ fontSize: "12px" }}>{user.email}</p>
                      <span className="horizontal" style={{ marginTop: "4px" }}>
                        <Switch
                          size="small"
                          checked={isUserActive}
                          onChange={(v) => handleUserStatus(v)}
                          loading={loadingStatus}
                        />
                        <p style={{ fontSize: "13px" }}>
                          {isUserActive ? "Disponible" : "No disponible"}
                        </p>
                      </span>
                    </span>
                    <Image
                      preview={false}
                      className="user-menu-image fade-in"
                      style={{ padding: "1px", width: "50px" }}
                      src={user && user.picture}
                      referrerPolicy={"no-referrer"}
                    />
                  </>
                )}
              </span>
              <span
                className="horizontal user-data-item"
                style={{ marginBottom: "4px" }}
                onClick={() =>
                  setSideDrawerConfig({ visible: true, content: "help" })
                }
              >
                <FiHelpCircle />
                <p>Documentación</p>
              </span>
              <span
                className="horizontal user-data-item"
                onClick={() => {
                  setInstance({});
                  navigate("/main");
                }}
              >
                <LiaExchangeAltSolid />
                <p>Workspaces</p>
              </span>
              <span
                className="horizontal user-data-item"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <FiLogOut />
                <p>Salir</p>
              </span>
            </div>
          }
        >
          <div>
            <Image
              preview={false}
              className="user-menu-image"
              style={{ padding: "1px" }}
              referrerPolicy={"no-referrer"}
              src={user && user.picture}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default BottomMenu;
